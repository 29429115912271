import React, {useState, useEffect} from 'react';

var md5 = require('md5');

export default function Gravatar(props) {

    const [hash, setHash] = useState(null);

    useEffect(() => {
        if (props.user && props.user.emailAddress) {
            setHash(md5(props.user.emailAddress.trim().toLowerCase()));
        }
    }, []);

    return <>
        <img src={"https://www.gravatar.com/avatar/" + hash + "?s=50&d=identicon"}  className="rounded-circle" />
    </>;
}