const { NODE_ENV } = process.env;

// const baseUrl = "https://api.trackmyssl.com";
const baseUrl = NODE_ENV === 'production' ? "https://api.trackmyssl.com" : "http://localhost:61134";

export default {
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    },

    acceptTerms(userId, onSuccess, onError, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + '/users/' + userId + '/accept-terms', requestOptions)
        .then(this.handleErrors)
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getUser(onSuccess, onError, token) {
        fetch(baseUrl + '/user', { headers: { 'Authorization': 'Bearer ' + token } })
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    addUser(onSuccess, onError, name, emailAddress, userId, token) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "id": userId,
            "emailAddress": emailAddress,
            "name": name,
        });

        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + '/users/add', requestOptions)
        .then(this.handleErrors)
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getTeamFromUser(onSuccess, onError, userId, token){
        fetch(baseUrl + '/teams/user/' + userId, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(this.handleErrors)
        .then(response => response.json())
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    editTeam(onSuccess, onError, name, teamId, token) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        var raw = JSON.stringify({
            "name": name,
        });
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(baseUrl + '/teams/' + teamId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    getDomain(onSuccess, onError, domainId, token){
        fetch(baseUrl + '/domains/' + domainId, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(this.handleErrors)
        .then(response => response.json())
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    searchDomains(onSuccess, onError, teamId, searchQuery, fromDate, toDate, status, pageNumber, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "teamId": teamId,
            "searchQuery": searchQuery,
            "fromDate": fromDate,
            "toDate": toDate,
            "status": status,
            "pageNumber": pageNumber,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(baseUrl + '/domains/search', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    addDomain(onSuccess, onError, domainUrl, friendlyName, teamId, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "domainAddress": domainUrl,
            "friendlyName": friendlyName,
            "teamId":teamId
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(baseUrl + '/domains/add', requestOptions)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    editDomain(onSuccess, onError, domainId, domainUrl, friendlyName, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "domainAddress": domainUrl,
            "friendlyName": friendlyName,
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(baseUrl + '/domains/' + domainId, requestOptions)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    deleteDomain(onSuccess, onError, domainId, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + '/domains/' + domainId, requestOptions)
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    deleteCertificate(onSuccess, onError, certificateId, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + '/certificates/' + certificateId, requestOptions)
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            })
            .catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    refreshCertificate(onSuccess, onError, domainId, token){
        fetch(baseUrl + '/domains/' + domainId + '/certificate', { headers: { 'Authorization': 'Bearer ' + token } })
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    getWebsiteInformation(onSuccess, onError, url, token){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "url": url,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(baseUrl + '/tools/website-information', requestOptions)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },
}