import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import _enum from '../shared/enum';
import moment from 'moment';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useToasts } from 'react-toast-notifications'
import ReactTooltip from 'react-tooltip';
import Api from '../shared/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEdit } from '@fortawesome/fontawesome-free-regular'
import { faTimes, faPlus, faFilter, faExclamationTriangle, faInfoCircle } from '@fortawesome/fontawesome-free-solid'
import { ReactComponent as Shield } from '../assets/images/TMSSL-shield.svg';
import String from '../shared/string';

function Landing(props) {
    const [team, setTeam] = useState(null);
    const [domains, setDomains] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [status, setStatus] = useState(1);
    const [clearDisabled, setClearDisabled] = useState(true);
    const [toDateTemp, setToDateTemp] = useState(null);
    const [fromDateTemp, setFromDateTemp] = useState(null);
    const [showFirstDomain, setShowFirstDomain] = useState(false);
    const [domainsLoading, setDomainsLoading] = useState(false);

    const { addToast } = useToasts();

    const deleteDomain = function (domainId) {
        Api.deleteDomain(
            () => getDomains(),
            () => addToast('Failed to delete the domain, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            domainId,
            props.token
        )
    }

    const setFilterDates = function () {
        setToDate(toDateTemp);
        setFromDate(fromDateTemp);
    };

    const clearFilters = function () {
        setToDate(null);
        setFromDate(null);
        setToDateTemp(null);
        setFromDateTemp(null);
        setStatus(1);
        setSearchQuery("");
    };

    const getTeam = function () {
        Api.getTeamFromUser(
            (data) => setTeam(data),
            () => addToast('Failed to fetch your account information, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            props.user.id, props.token
        )
    };

    const getDomains = function () {
        setDomainsLoading(true);
        Api.searchDomains(
            (data) => {
                setDomains(data);
                setDomainsLoading(false);
            },
            () => {
                addToast('Failed to fetch your desired search, please try again.', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setDomainsLoading(false);
            },
            team.id, searchQuery, fromDate, toDate, status, 0, props.token
        )
    }

    useEffect(() => {
        if (team !== null) {
            getDomains();
        }
    }, [team]);

    useEffect(() => {
        if (team !== null) {
            getDomains();
        }
        if (searchQuery === "" && status === 1 && toDate === null && fromDate === null) {
            setClearDisabled(true);
            setShowFirstDomain(true);
        } else {
            setShowFirstDomain(false);
            setClearDisabled(false);
        }
    }, [searchQuery, toDate, fromDate, status]);

    useEffect(() => {
        if (searchQuery === "" && status === 1 && toDate === null && fromDate === null && domains != null && domains.length == 0 ) {
            setShowFirstDomain(true);
        } else {
            setShowFirstDomain(false);
        }
    }, [domains]);

    useEffect(() => {
        getTeam();
    }, []);

    return (
        <>
            {team !== null &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body domain-table">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-lg-4">
                                            <div className="d-flex">
                                                <h4 className='primary'>Search: </h4>
                                                <input type="text" className="form-control me-0 ms-1 mx-lg-1" placeholder="e.g. trackmyssl.com" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <div className='row justify-content-between'>
                                                <div className="col-12 col-md-auto">
                                                    <div className="filters-container">
                                                        <button className="btn btn-light mt-1 mt-lg-0 ms-0 me-0 ms-lg-1 me-md-1 primary" type="button" data-bs-toggle="modal" data-bs-target="#dateModal">
                                                            Filter by Date <FontAwesomeIcon className="ms-1" icon={faCalendar} />
                                                        </button>
                                                        <div class="dropdown me-md-1 me-0 mt-1 mt-lg-0">
                                                            <button class="btn btn-light dropdown-toggle primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {status === 1 && <>Filter by Status <FontAwesomeIcon className="ms-1" icon={faFilter} /></>}{status !== 1 && <>Status: {_enum.CertificateStatus[status]}</>}
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(1)}>All</button></li>
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(500)}>Healthy</button></li>
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(60)}>Warning</button></li>
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(30)}>Danger</button></li>
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(0)}>Expired</button></li>
                                                                <li><button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(999)}>No Certificate</button></li>
                                                            </ul>
                                                        </div>
                                                        <button className="btn btn-danger mt-1 mt-lg-0" disabled={clearDisabled} onClick={() => clearFilters()}>Clear Filters <FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto">
                                                    <div className="button-container mt-1 mt-lg-0">
                                                        {showFirstDomain && !domainsLoading &&
                                                            <div class="tooltip bs-tooltip-bottom tooltip-show" role="tooltip">
                                                                <div class="tooltip-arrow"></div>
                                                                <div class="tooltip-inner">
                                                                    Add your first Domain here!
                                                                </div>
                                                            </div>}
                                                        <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#addDomainModal">Add Domain <FontAwesomeIcon className="ms-1" icon={faPlus} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <AddDomain onChange={getDomains} teamId={team.id} token={props.token} domains={domains} />
                                        <div class="modal fade" id="dateModal" tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title primary" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                        <button type="button" className="btn no-style close" data-bs-toggle="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <p className="font-weight-semi-bold  mt-1 mb-1 primary">Period Start</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group mb-0">
                                                                    {!fromDateTemp &&
                                                                        <>
                                                                            <input className="form-control mb-1" type="date" value={null} onChange={(e) => setFromDateTemp(e.target.value)} />
                                                                        </>}
                                                                    {fromDateTemp &&
                                                                        <input className="form-control mb-1" type="date" value={fromDateTemp} onChange={(e) => setFromDateTemp(e.target.value)} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <p className="font-weight-semi-bold  mt-1 mb-1 primary">Period End</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group mb-0">
                                                                    {!toDateTemp &&
                                                                        <>
                                                                            <input className="form-control mb-1" type="date" value={null} onChange={(e) => setToDateTemp(e.target.value)} />
                                                                        </>}
                                                                    {toDateTemp &&
                                                                        <input className="form-control mb-1" type="date" value={toDateTemp} onChange={(e) => setToDateTemp(e.target.value)} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light btn-sm ms-2" data-bs-toggle="modal">Cancel</button>
                                                        <button type="button" className="btn btn-success btn-sm ms-2" data-bs-toggle="modal" onClick={() => setFilterDates()}>
                                                            Filter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <table className='domain-table'>
                                        <thead className="primary">
                                            <tr>
                                                <th className='text-center'>Status</th>
                                                <th>Name</th>
                                                <th>Days Remaining</th>
                                                <th className='d-none d-xl-table-cell'>Domain Address</th>
                                                <th className='text-end d-none d-lg-table-cell'>Last Checked</th>
                                                <th className='d-none d-md-table-cell text-end'>Certificate Expiry</th>
                                                <th className='text-end'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {domains !== null && domains.map((domain) => {
                                                var certificate = domain.certificates[0];
                                                return (
                                                    <>
                                                        <ReactTooltip place='left' />
                                                        <Domain deleteDomain={deleteDomain} domains={domains} domain={domain} certificate={certificate} id={domain.id} />
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default Landing;

function Domain(props) {
    const [statusClass, setStatusClass] = useState('pro-0');
    const [daysLeftLabel, setDaysLeftLabel] = useState(null);
    const [daysLeftCount, setDaysLeftCount] = useState(1);
    const calculateStatus = function () {
        var daysLeft = 0;
        var certificate = props.certificate;
        var a = moment(certificate.validUntil);
        var b = moment(moment.now());

        daysLeft = a.diff(b, 'days');
        setDaysLeftLabel(daysLeft);

        var percentage = 0;

        if (daysLeft > 28) {
            percentage = 20;

            setStatusClass("pro-green-100 pro-" + percentage);
        } else {
            percentage = Math.max(0, 100 - daysLeft * (80 / 28).toFixed());
            percentage = Math.min(100, percentage);
            var colourPercentage = 0;
            var colourTag = "";
            setDaysLeftCount(daysLeft);

            if (percentage <= 25) {
                colourPercentage = 100 - (percentage * 4);
                colourTag = "green-" + colourPercentage;
            }
            else if (percentage > 25 && percentage <= 50) {
                colourPercentage = 100 - ((percentage - 25) * 4);
                colourTag = "yellow-" + colourPercentage;

            } else if (percentage > 50 && percentage <= 75) {
                colourPercentage = 100 - ((percentage - 50) * 4);
                colourTag = "orange-" + colourPercentage;

            } else if (percentage > 75 && percentage <= 100) {
                colourPercentage = 100 - ((percentage - 75) * 4);
                colourTag = "red-" + colourPercentage;
            }

            setStatusClass("pro-" + colourTag + " pro-" + percentage);
        }

    }

    useEffect(() => {
        if (props.certificate !== null && props.certificate !== undefined) {
            calculateStatus();
        }
    }, []);

    useEffect(() => {
        if (props.certificate !== null && props.certificate !== undefined) {
            calculateStatus();
        }
    }, [props.domains]);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header><h4>{props.domain.friendlyName}</h4></Popover.Header>
            <Popover.Body>
                <div className='row'>
                    <div className='col'>
                        <h5>Domain URL</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <a href={props.domain.domainAddress} rel="noreferrer" target="_blank">{props.domain.domainAddress}</a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h5>Certificate Issuer</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        {props.certificate !== undefined && <>
                            {props.certificate.hostedBy}
                        </>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h5>Expiry Date</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        {props.certificate !== null && props.certificate !== undefined && props.certificate.validUntil !== null &&
                            <>
                                {moment(props.certificate.validUntil).format("DD/MM/yyyy")}
                            </>}
                        {(props.certificate === undefined || props.certificate === null) && <>No Certificate</>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h5>Last Checked</h5>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {moment(props.domain.certificateLastChecked).fromNow()}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    const trigger = ["hover", "focus", "active"];
    return (
        <>
            <tr>
                <td className='status-container'>
                    {props.certificate != null &&
                        <div className='progress-disc-container'>
                            <div className='disc'>
                            </div>
                            <div className={"progress-disc " + statusClass}></div>
                            <Shield />
                        </div>}
                    {props.certificate == null &&
                        <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} />}
                </td>
                <td>
                    {props.certificate !== null && <>
                        <button className='no-style primary' data-bs-toggle="modal" data-bs-target={"#info-" + props.domain.id}><FontAwesomeIcon className="me-1" icon={faInfoCircle} /></button>
                        <Link className='domain-link primary' to={"/domain/" + props.domain.id}>{props.domain.friendlyName}</Link>
                        <div class="modal fade" id={"info-" + props.domain.id} tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4><FontAwesomeIcon className="me-1" icon={faInfoCircle} />{props.domain.friendlyName}</h4>
                                    </div>
                                    <div className='modal-body'>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>Domain URL</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <a href={props.domain.domainAddress} rel="noreferrer" target="_blank">{props.domain.domainAddress}</a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>Certificate Issuer</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                {props.certificate !== undefined && <>
                                                    {props.certificate.hostedBy}
                                                </>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>Expiry Date</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                {props.certificate !== null && props.certificate !== undefined && props.certificate.validUntil !== null &&
                                                    <>
                                                        {moment(props.certificate.validUntil).format("DD/MM/yyyy")}
                                                    </>}
                                                {(props.certificate === undefined || props.certificate === null) && <>No Certificate</>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>Last Checked</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col'>
                                                {moment(props.domain.certificateLastChecked).fromNow()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                    }
                    {props.certificate === null &&
                        <Link className='domain-link primary' to={"/domain/" + props.domain.id}>{props.domain.friendlyName}</Link>
                    }
                </td>
                <td>
                    {(props.certificate !== null && props.certificate !== undefined) &&
                        <>
                            <div className={"progress-tag " + statusClass}>
                                <div className='text'>
                                    {daysLeftCount > 0 && <>
                                        {daysLeftLabel} day{daysLeftLabel > 1 && <>s</>}
                                    </>}
                                    {daysLeftCount <= 0 && <>
                                        Expired
                                    </>}
                                </div>
                            </div>
                        </>
                    }
                    {(props.certificate === null || props.certificate === undefined) &&
                        <>
                            <h5 className='primary no-certificate'>
                                No Certificate
                            </h5>
                        </>
                    }
                </td>
                <td className='d-none d-xl-table-cell'><a href={props.domain.domainAddress} target="_blank">{props.domain.domainAddress}</a></td>
                <td className='text-end d-none d-lg-table-cell'>
                {props.certificate !== null && props.certificate !== undefined && <>{moment(props.domain.certificateLastChecked).fromNow()}</>}
                </td>
                <td className='text-end d-none d-md-table-cell'>
                    {props.certificate !== null && props.certificate !== undefined && <>{moment(props.certificate.validUntil).format("DD MMMM YYYY HH:mm")}</>}
                </td>
                <td>
                    <div className="button-container d-flex justify-content-end">
                        <Link data-tip={"Edit " + props.domain.friendlyName} to={"/domain/" + props.domain.id} className="no-style primary mx-md-1 mx-0"><FontAwesomeIcon className="ms-1" icon={faEdit} /></Link>
                        <button data-tip={"Delete " + props.domain.friendlyName} className="no-style delete text-danger mx-1" data-bs-toggle="modal" data-bs-target={"#delete-" + props.id}><FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                    </div>
                </td>
            </tr>
            <div class="modal fade" id={"delete-" + props.id} tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editDomainLabel">Are you sure you want to delete {props.domain.friendlyName}?</h5>
                            <button type="button" className="close no-style" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-danger btn-sm" type="submit" data-bs-dismiss="modal" onClick={() => props.deleteDomain(props.domain.id)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function AddDomain(props) {
    const [domainAddress, setDomainAddress] = useState("");
    const [friendlyName, setFriendlyName] = useState("");
    const [canAddDomain, setCanAddDomain] = useState(false);
    const [overrideAdditionWarning, setOverrideAdditionWarning] = useState(true);
    const [websiteInformationIsLoading, setWebsiteInformationIsLoading] = useState(false);

    const { addToast } = useToasts();

    const addDomain = e => {
        e.preventDefault();
        var cleanedDomainAddress = cleanDomainAddress(domainAddress);
        var isAlreadyInList = false;
        if (props.domains !== null && props.domains.length !== 0) {
            props.domains.forEach(domain => {
                if (domain.domainAddress === cleanedDomainAddress) {
                    isAlreadyInList = true;
                }
            });
        }
        if (!isAlreadyInList || overrideAdditionWarning) {//if not in list or if they want to add it anyway
            if (props.teamId !== null && canAddDomain) {
                Api.addDomain(
                    () => {
                        setDomainAddress("");
                        setFriendlyName("");
                        props.onChange();
                    },
                    () => {
                        setDomainAddress("");
                        setFriendlyName("");
                        addToast('No Certificate found, please check the domain information.', {
                            appearance: 'error',
                            autoDismiss: true,
                        });
                        props.onChange();
                    },
                    domainAddress, friendlyName, props.teamId,
                    props.token
                )
            }
        } else {
            setOverrideAdditionWarning(true);
        }
    };

    const cleanDomainAddress = domainAddress => {
        var startsWithHttp = new RegExp("^http.*$");

        if (!startsWithHttp.test(domainAddress)) {
            domainAddress = "https://" + domainAddress;
        }
        var subpageOrTrailingSlash = new RegExp("^.*[/]{2}.*[/].*$");
        while (subpageOrTrailingSlash.test(domainAddress)) {
            domainAddress = domainAddress.substring(0, domainAddress.lastIndexOf('/'));
        }

        return domainAddress;
    }

    const getWebsiteInformation = function(force) {
        if(force || String.isNullOrEmpty(friendlyName))
        {
            setWebsiteInformationIsLoading(true);
            Api.getWebsiteInformation(
                (data) => {
                    if (data) {
                        setFriendlyName(data.name);
                        setWebsiteInformationIsLoading(false);
                    }
                },
                () => {
                    setWebsiteInformationIsLoading(false);
                },
                domainAddress,
                props.token
            );
        }
    };

    useEffect(() => {
        setOverrideAdditionWarning(false);
        if (friendlyName !== "" && domainAddress !== "") {
            setCanAddDomain(true);
        } else {
            setCanAddDomain(false);
        }
    }, [friendlyName, domainAddress]);

    return (
        <>
            <div className="modal fade" id="addDomainModal" tabIndex="-1" role="dialog" aria-labelledby="signOutModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addDomainLabel">Add new domain</h5>
                                <button type="button" className="close no-style" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <h5>URL</h5>
                                    </div>
                                    <div className="col-12">
                                        <input className="form-control" type="text" value={domainAddress} onChange={(e) => setDomainAddress(e.target.value)} onBlur={() => getWebsiteInformation(false)} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <h5>Project  Name</h5>
                                    </div>
                                    <div className="col-12">
                                        <div className='input-group'>
                                        <input className="form-control" type="text" value={friendlyName} disabled={websiteInformationIsLoading} onChange={(e) => setFriendlyName(e.target.value)} />
                                        <span className='input-group-btn input-group-append'>
                                            <button type="button" className="btn btn-default" onClick={() => {getWebsiteInformation(true)}}><i class="mdi mdi-refresh spinner"></i></button>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                {overrideAdditionWarning &&
                                    <div className="row">
                                        <div className="col-12 text-danger mt-1">
                                            <strong>This domain already exists in your dashboard.</strong>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" disabled={!canAddDomain} data-bs-dismiss="modal" type="submit" onClick={addDomain}>Add Domain</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {overrideAdditionWarning &&
                <div className='are-you-sure-popup modal show'>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addDomainLabel">This domain already exists in your dashboard</h5>
                                <button type="button" className="close no-style" onClick={() => { setOverrideAdditionWarning(false); setDomainAddress(""); setFriendlyName(""); }} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default btn-sm ms-2" onClick={() => { setOverrideAdditionWarning(false); setDomainAddress(""); setFriendlyName(""); }}>Cancel</button>
                                <button className="btn btn-danger btn-sm" disabled={!canAddDomain} data-bs-dismiss="modal" type="submit" onClick={addDomain}>Add Domain Anyway</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
