import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-regular'
import { faCheck, faTimes, faReply } from '@fortawesome/fontawesome-free-solid'

import { useToasts } from 'react-toast-notifications'
import { Link } from 'react-router-dom';

import Api from '../shared/api';

function ManageAccount(props) {
    const [team, setTeam] = useState(null);
    const [teamNameInEdit, setTeamNameInEdit] = useState(false);
    const [teamName, setTeamName] = useState("");
    const { addToast } = useToasts();

    const getTeam = function () {
        Api.getTeamFromUser(
            (data) => setTeam(data),
            () => addToast('Failed to fetch your account information, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            props.user.id, props.token
        )
    };

    useEffect(() => {
        if (team !== null) {
            setTeamName(team.name);
        }
    }, [team]);

    const editTeamName  = e => {
        e.preventDefault();
        if (teamName !== team.name && teamName !== "") {
            Api.editTeam(
                (data) => {
                    setTeam(data);
                    setTeamNameInEdit(false);
                    props.onTeamNameChange();
                },
                () => addToast('Failed to save changes, please try again.', {
                    appearance: 'error',
                    autoDismiss: true,
                }),
                teamName, team.id,
                props.token
            )
        } else {
            setTeamNameInEdit(false);
            setTeamName(team.name);
        }
    };

    useEffect(() => {
        getTeam();
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h1 className="mb-0 primary">{props.user.name}</h1>
                                    </div>
                                    <div className="col-auto me-0 ">
                                        {teamNameInEdit &&
                                            <button className="btn btn-danger p-1 mx-1" onClick={() => setTeamNameInEdit(!teamNameInEdit)}>Cancel <FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                                        }
                                        {!teamNameInEdit &&
                                            <button className="btn btn-primary p-1 mx-1" onClick={() => setTeamNameInEdit(!teamNameInEdit)}>Edit <FontAwesomeIcon className="ms-1" icon={faEdit} /></button>
                                        }
                                        <Link className="btn btn-light p-1 mx-1 my-1 my-md-0" to="/">Back <FontAwesomeIcon className="ms-1" icon={faReply} /></Link>
                                    </div>
                                </div>
                            </div>
                            <form>
                            <div className="card-header">
                                <div className="row align-items-center mt-1">
                                    <div className="col-5 col-lg-3 justify-content-center primary">
                                        <h5>Account Name</h5>
                                    </div>
                                    <div className="col-7 col-lg-3 justify-content-center">
                                        {props.user.name}
                                    </div>
                                </div>
                                {!teamNameInEdit &&
                                    <div className="row align-items-center my-1">
                                        <div className="col-5 col-lg-3 justify-content-center primary">
                                            <h5>Team Name</h5>
                                        </div>
                                        <div className="col-7 col-lg-3 justify-content-center">
                                            {teamName}
                                        </div>
                                    </div>
                                }
                                {teamNameInEdit &&
                                    <>
                                        <div className="row align-items-center my-1">
                                            <div className="col-5 col-lg-3 primary">
                                                <h5>Team Name</h5>
                                            </div>
                                            <div className="col-7 col-lg-3">
                                                <input className="form-control" autoFocus={true} value={teamName} type="text" onChange={(e) => setTeamName(e.target.value)} />
                                            </div>
                                        </div>
                                    </>}
                            </div>
                            {teamNameInEdit &&
                                <div className="card-footer">
                                    <div className="row justify-content-end align-items-center my-1">
                                        <div className="col-auto">
                                            <button className="p-1 btn mx-1 btn-success" disabled={teamName===""} type="submit" onClick={editTeamName}>Confirm <FontAwesomeIcon className="ms-1" icon={faCheck} /></button>
                                        </div>
                                    </div>
                                </div>
                            }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ManageAccount;