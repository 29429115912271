import React, { useState, useEffect } from 'react';
import Api from '../shared/api';
import authentication from '../auth/auth';
import Gravatar from './gravatar';

function Header(props) {
    const [team,setTeam] = useState(null);
    const [names, setNames] = useState([]);

    const getTeam = function () {
        Api.getTeamFromUser(
            (data) => setTeam(data),
            (error) => console.log(error),
            props.user.id, props.token
        )
    };

    useEffect(() => {
        getTeam();
        if(props.user.name !== undefined){
            setNames(props.user.name.split(' '));
        }
    }, [props.user]);
    return (
        <>
            <nav className="navbar navbar-light navbar-glass navbar-top sticky-kit navbar-expand-lg">
                <a className="navbar-brand me-1 me-sm-3" href="/">
                    <div className="d-flex align-items-center">
                        <img className="mx-2" src="/images/logos/TMSSL-logo-main.svg" alt="" width="100" />
                    </div>
                </a>
                <ul className="topnav navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
                    {props.user !== null && props.user !== undefined &&
                        <>
                            <li class="dropdown nav-item">
                                <a class="nav-link dropdown-toggle nav-user arrow-none me-0 dropdown-toggle dropdown-toggle arrow-none nav-link" id="apps-email" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="/">
                                    <span class="account-user-avatar">
                                        <Gravatar user={props.user} />
                                    </span>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="apps-email">
                                    <a class="dropdown-item primary" data-menu-key="email-inbox" href="/manage-account">
                                        Manage Account
                                    </a>
                                    <button type="button" className="dropdown-item primary" data-bs-toggle="modal" data-bs-target="#signOutModal">Sign Out</button>
                                </div>
                            </li>
                        </>}

                </ul>
            </nav>
            <div className="modal fade" id="signOutModal" tabIndex="-1" role="dialog" aria-labelledby="signOutModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="signOutModalLabel">Are you sure you want to Sign Out?</h5>
                            <button type="button" className="close no-style" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ms-2" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => authentication.signOut()}>Sign Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;
