import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Loader from './shared/loader';
import './assets/scss/Saas.scss';
import './App.scss';

import authentication from './auth/auth';
import Api from './shared/api';

import Header from './shared/header';
import Footer from './shared/footer';
import Landing from './dashboard/landing';
import Domain from './domain/domain';
import ManageAccount from './manage-account/manage-account';
import { ToastProvider } from 'react-toast-notifications'

function App() {
  const [user, setUser] = useState(null);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const token = authentication.getAccessToken();
  useEffect(() => {
    getUser();
  }, []);

  const getUser = function () {
    Api.getUser(
      (data) => {
        setUser(data);
      },
      () => {
        setUser(null);
        addNewUser();
      },
      token.accessToken
    );
  };

  const addNewUser = function () {
    if (user == null && token.idToken !== null) {
      var newUser = token.idToken.claims;
      Api.addUser(
        (data) => setUser(data),
        () => console.log("Unable to find user account, please contact system administrator."),
        newUser.name, newUser.email, newUser.sub, token.accessToken
      )
    }
  };

  const acceptTerms = function () {
    Api.acceptTerms(
      user.id,
      () => getUser(),
      () => console.log("Failure accepting terms and conditions, please try again."),
      token.accessToken
    )
  }

  useEffect(() => {
    if(user !== null){
      setHasAcceptedTerms(user.hasAcceptedTerms);
    }
  }, [user])

  return (
    <>
      {!user && <>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <button className="mt-1 btn btn-primary btn-sm" onClick={() => authentication.signOut()}>Sign Out</button>
          </div>
        </div>
        <Loader />
      </>}
      {user !== null && hasAcceptedTerms &&
        <div className="wrapper">
          <Header user={user} token={token.accessToken} />
          <Router>
            <ToastProvider>
              <Routes>
                <Route path="/" element={<Landing user={user} token={token.accessToken} />} />
                <Route path="/manage-account" element={<ManageAccount user={user} token={token.accessToken} onTeamNameChange={getUser} />} />
                <Route exact path="/domain/:domainId" element={<Domain token={token.accessToken} />} />
              </Routes>
            </ToastProvider>
          </Router>
          <Footer />
        </div>
      }{user !== null && !hasAcceptedTerms &&
        <>
              <div className="modal d-inline">
                <div className="modal-dialog terms-modal" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="editDomainLabel">
                        By clicking accept you are choosing to accept our <a target="_blank" href="https://www.trackmyssl.com/terms-conditions">terms and conditions</a>.
                      </h5>
                    </div>
                    <div className="modal-footer">
                      <button className="mt-1 btn btn-primary btn-sm" onClick={() => acceptTerms()}>Accept</button>
                    </div>
                </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default App;