import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import authentication from './auth/auth';
authentication.initialize();

authentication.run(() => {
    ReactDOM.render(
        <React.StrictMode>
                <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
});
