import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Loader from '../shared/loader';
import { useNavigate, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Api from '../shared/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-regular'
import { faCheck, faInfoCircle, faReply, faTimes } from '@fortawesome/fontawesome-free-solid'
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';

function Domain(props) {
    let params = useParams();
    const history = useNavigate();
    const { addToast } = useToasts();

    const [domain, setDomain] = useState(null);
    const [domainAddress, setDomainAddress] = useState("");
    const [friendlyName, setFriendlyName] = useState("");
    const [canEditDomain, setCanEditDomain] = useState(false);
    const [domainInEdit, setDomainInEdit] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    const getDomain = function () {
        Api.getDomain(
            (data) => setDomain(data),
            () => addToast('Failed to fetch the domain, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            params.domainId, props.token
        )
    };

    const deleteDomain = function (domainId) {
        Api.deleteDomain(
            () => history('/'),
            () =>
                addToast('Failed to delete the domain, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            domainId,
            props.token
        )
    }

    const refreshCertificate = function () {
        Api.refreshCertificate(
            () => {
                getDomain();
                setRefreshing(false);
            },
            () => {
                addToast('Failed to refresh the certificate, please try again.', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setRefreshing(false);
            },
            params.domainId, props.token
        )
    };

    const deleteCertificate = function (certificateId) {
        Api.deleteCertificate(
            () => getDomain(),
            () => addToast('Failed to delete the certificate, please try again.', {
                appearance: 'error',
                autoDismiss: true,
            }),
            certificateId, props.token
        )
    };

    const editDomain = e => {
        e.preventDefault();
        if (props.teamId !== null && domainAddress !== "") {
            Api.editDomain(
                (data) => {
                    setDomain(data);
                    setDomainInEdit(false);
                },
                () => addToast('Failed to save the changes to the domain, please try again.', {
                    appearance: 'error',
                    autoDismiss: true,
                }),
                params.domainId, domainAddress, friendlyName,
                props.token
            )
        }
    };

    useEffect(() => {
        if (domainAddress !== "" && friendlyName !== "") {
            setCanEditDomain(true);
        } else {
            setCanEditDomain(false);
        }
    }, [friendlyName, domainAddress]);

    useEffect(() => {
        if (domain !== null) {
            setFriendlyName(domain.friendlyName);
            setDomainAddress(domain.domainAddress);
        }
    }, [domain]);

    useEffect(() => {
        if (params.domainId != null) {
            getDomain();
        }
    }, []);

    return (<>
        {!domain && <Loader />}
        {domain !== null &&
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h1 className="mb-0 primary">{domain.friendlyName}</h1>
                                    </div>
                                    <div className="col-12 col-md-auto me-0 ">
                                        <div className='filters-container'>
                                            <button className="btn btn-danger p-1 mx-1 my-1 my-md-0" data-bs-toggle="modal" data-bs-target="#delete-modal">Delete <FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                                            <div class="modal fade" id="delete-modal" tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="editDomainLabel">Are you sure you want to delete {domain.friendlyName}?</h5>
                                                            <button type="button" className="close no-style" data-bs-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Cancel</button>
                                                            <button className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => deleteDomain(domain.id)}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {domainInEdit &&
                                                <button className="btn btn-danger p-1 mx-1 my-1 my-md-0" onClick={() => setDomainInEdit(!domainInEdit)}>Cancel <FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                                            }
                                            {!domainInEdit &&
                                                <button className="btn btn-primary p-1 mx-1 my-1 my-md-0" onClick={() => setDomainInEdit(!domainInEdit)}>Edit <FontAwesomeIcon className="ms-1" icon={faEdit} /></button>
                                            }
                                            <Link className="btn btn-light p-1 mx-1 my-1 my-md-0" to="/">Back <FontAwesomeIcon className="ms-1" icon={faReply} /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {domain.certificates !== null && domain.certificates.map((certificate, i = 0) => {
                                i++;
                                if (i === 1) {
                                    return (
                                        <div className="card-body pb-0">
                                            <div className="row align-items-center mt-1">
                                                <div className="col-5 col-lg-3 justify-content-center primary">
                                                    <h5>Expiry Date</h5>
                                                </div>
                                                <div className="col-7 col-lg-3 justify-content-center">
                                                    {moment(certificate.validUntil).format("DD/MM/yyyy")}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (<></>);
                                }
                            })}
                            {!domainInEdit &&
                                <div className="card-body pt-0">
                                    <div className="row align-items-center my-1">
                                        <div className="col-5 col-lg-3 justify-content-center primary">
                                            <h5>Friendly Name</h5>
                                        </div>
                                        <div className="col-7 col-lg-3 justify-content-center">
                                            {domain.friendlyName}
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-1">
                                        <div className="col-5 col-lg-3 justify-content-center primary">
                                            <h5>Domain URL</h5>
                                        </div>
                                        <div className="col-7 col-lg-3 justify-content-center">
                                            <a href={domain.domainAddress} rel="noreferrer" target="_blank">{domain.domainAddress}</a>
                                        </div>
                                    </div>
                                </div>}
                            {domainInEdit && <>
                                <form>
                                    <div className="card-body pb-0">
                                        <div className="row align-items-center my-1">
                                            <div className="col-5 col-lg-3 primary">
                                                <h5>Friendly Name</h5>
                                            </div>
                                            <div className="col-7 col-lg-3">
                                                <input className="form-control" type="text" value={friendlyName} onChange={(e) => setFriendlyName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-1">
                                            <div className="col-5 col-lg-3 primary">
                                                <h5>Domain URL</h5>
                                            </div>
                                            <div className="col-7 col-lg-3">
                                                <input className="form-control" type="text" value={domainAddress} onChange={(e) => setDomainAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row justify-content-end align-items-center my-1">
                                            <div className="col-auto">
                                                <button onClick={editDomain} disabled={!canEditDomain} type="submit" className="btn btn-success p-1 mx-1" >Confirm <FontAwesomeIcon className="ms-1" icon={faCheck} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                            }
                            <div className="card-body">
                                <div className="row justify-content-between align-items-center my-1">
                                    <div className="col-auto">
                                        <h2 className="mb-0 primary">Certificate History</h2>
                                    </div>
                                    <div className="col-12 col-md-auto">
                                        <div className='filters-container'>
                                            {!refreshing && <button className="btn btn-success p-1 mt-1" onClick={() => { setRefreshing(true); refreshCertificate() }}>Refresh Certificate <FontAwesomeIcon className="ms-1" icon={faSync} /></button>}
                                            {refreshing && <button className="btn btn-primary refreshing p-1 mt-1" >Refreshing <FontAwesomeIcon className="ms-1" icon={faSync} /></button>}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <table>
                                    <thead className="primary">
                                        <tr>
                                            <th>
                                                <div className='d-none d-lg-block'>
                                                    Thumbprint
                                                </div>
                                            </th>
                                            <th>
                                                Issuer
                                            </th>
                                            <th className='text-end'>
                                                Added Date
                                            </th>
                                            <th className='text-end'>
                                                Valid From
                                            </th>
                                            <th className='text-end'>
                                                Valid Until
                                            </th>
                                            <th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domain.certificates !== null && domain.certificates.map((certificate) => {
                                            return (
                                                <>
                                                    <ReactTooltip />
                                                    <Certificate certificate={certificate} deleteCertificate={deleteCertificate} />
                                                </>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
    </>);
}

export default Domain;

function Certificate(props) {
    return (
        <>
            <tr>
                <td>
                    <button className='no-style primary' data-bs-toggle="modal" data-bs-target={"#info-" + props.certificate.id}><FontAwesomeIcon className="me-1" icon={faInfoCircle} /></button>
                    <div class="modal fade" id={"info-" + props.certificate.id} tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4><FontAwesomeIcon className="me-1" icon={faInfoCircle} />{moment(props.certificate.certificateAdded).format("DD/MM/yyyy HH:mm")}</h4>
                                </div>
                                <div className='modal-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Valid From</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{moment(props.certificate.validFrom).format("DD/MM/yyyy")}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Valid To</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{moment(props.certificate.validUntil).format("DD/MM/yyyy")}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Thumbprint</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.thumbprint}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Serial Number</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.serialNumber}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Issuer</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.hostedBy}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Issuer Details</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.issuer}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Issued To</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.issuedTo}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <h5>Signture</h5>
                                            </div>
                                            <div className='row'>
                                                <p>{props.certificate.signature}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='primary d-none d-lg-inline'>
                        {props.certificate.thumbprint}
                    </div>
                </td>
                <td>
                    {props.certificate.hostedBy}
                </td>
                <td className='text-end'>
                    <div className='d-none d-sm-inline'>
                        {moment(props.certificate.certificateAdded).format("DD/MM/YY HH:mm")}
                    </div>
                    <div className='d-inline d-sm-none'>
                        {moment(props.certificate.certificateAdded).format("DD/MM")}
                    </div>
                </td>
                <td className='text-end'>
                        {moment(props.certificate.validFrom).format("DD/MM/YY")}
                </td>
                <td className='text-end'>
                        {moment(props.certificate.validUntil).format("DD/MM/YY")}
                </td>
                <td className='p-0'>
                    <button data-tip="Delete Certificate" className="no-style delete text-danger mx-1" data-bs-toggle="modal" data-bs-target={"#delete-" + props.certificate.id}><FontAwesomeIcon className="ms-1" icon={faTimes} /></button>
                    <div class="modal fade" id={"delete-" + props.certificate.id} tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editDomainLabel">Are you sure you want to delete this certificate?</h5>
                                    <button type="button" className="close no-style" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    This will only remove the entry here and not from your site itself.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default btn-sm ms-2" data-bs-dismiss="modal">Cancel</button>
                                    <button className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => props.deleteCertificate(props.certificate.id)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}