import React from 'react';
import * as msal from 'msal'
const { NODE_ENV } = process.env;

const state = {
    stopLoopingRedirect: false,
    config: {
        scopes: [],
        cacheLocation: null,
    },
    launchApp: null,
    accessToken: null,
    msalObj: null,
}

const redirectUrl = NODE_ENV === 'production' ? 'https://app.trackmyssl.com/' : 'http://localhost:3000';

const SESSION_STORAGE = 'sessionStorage'
const AUTHORIZATION_KEY = 'Authorization'

var isIE = function isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ") > -1;
    var msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

var B2C_SCOPES = {
    API_ACCESS: {
        scopes: ['openid', 'https://ssldashboard.onmicrosoft.com/9a90f59a-3830-4e80-9236-accd057bc48b/apiAccess'],
    },
};

var msalAppConfig = {
    auth: {
        clientId: '9a90f59a-3830-4e80-9236-accd057bc48b',
        // authority: 'https://ssldashboard.b2clogin.com/ssldashboard.onmicrosoft.com/b2c_1a_signup_signin',
        authority: 'https://auth.trackmyssl.com/ssldashboard.onmicrosoft.com/b2c_1a_signup_signin',
        redirectUri: redirectUrl,
        validateAuthority: false,
        postLogoutRedirectUri: window.location.origin
    },
    cache: {
        cacheLocation: SESSION_STORAGE,
        storeAuthStateInCookie: isIE()
    }
};

function acquireToken(successCallback) {
    const account = msalApp.getAccount();

    if (!account) {
        msalApp.clearCache()
        msalApp.loginRedirect(B2C_SCOPES.API_ACCESS)
    } else {
        const accessTokenRequest = {
            scopes: ['openid', 'https://ssldashboard.onmicrosoft.com/9a90f59a-3830-4e80-9236-accd057bc48b/apiAccess'],
            account: account,
        };

        msalApp.acquireTokenSilent(accessTokenRequest).then(accessToken => {
            window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken.accessToken)
            state.accessToken = accessToken;

            if (state.launchApp) {
                state.launchApp()
            }
            if (successCallback) {
                successCallback()
            }
        }, error => {
            if (error) {
                msalApp.acquireTokenRedirect(B2C_SCOPES.API_ACCESS)
            }
        })

    }
}

let msalApp;

var authentication = {
    initialize: () => {
        msalApp = new msal.UserAgentApplication(msalAppConfig)
    },
    run: (launchApp) => {
        state.launchApp = launchApp
        msalApp.handleRedirectCallback(error => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token."
                console.log(errorMessage);
            }
        });
        acquireToken();
    },
    required: (WrappedComponent, renderLoading) => {
        return class extends React.Component {
            constructor(props) {
                super(props)
                this.state = {
                    signedIn: false,
                    error: null
                }
            }

            render() {
                if (this.state.signedIn) {
                    return (
                        <WrappedComponent {...this.props} />)
                }
                return typeof renderLoading === 'function' ? renderLoading() : null
            }
        }
    },

    signOut: function signOut() {
        return msalApp.logout();
    },
    getAccessToken: function getAccessToken() {
        return state.accessToken;
    }
};

export default authentication;