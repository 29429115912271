import React from 'react';
import moment from 'moment';

function Footer() {
    var thisYear = moment(moment.now());
    return (
        <footer>
            <div className='container-fluid'>
                <hr/>
                <div className='row justify-content-between pt-2 pb-3'>
                    <div className='col-auto'>
                        <div className='copyright-text'>
                            &copy; {moment(thisYear).format("yyyy")} I-Finity Associates Ltd.
                        </div>
                    </div>
                    <div className='col-auto'>
                        <ul className="footer-nav">
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noreferrer"  href="https://www.trackmyssl.com/terms-conditions">Terms & Conditions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noreferrer"  href="https://www.trackmyssl.com/privacy-policy">Privacy Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noreferrer"  href="https://www.trackmyssl.com/cookie-policy">Cookie Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noreferrer"  href="https://www.trackmyssl.com/end-user-license-agreement">End User License Agreement</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;